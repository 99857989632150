<script>
import axios from "axios";
import Swal from "sweetalert2";

import Layout from "../../layouts/auth";
import { authMethods, authFackMethods, notificationMethods } from "@/state/helpers";
import { mapState } from "vuex";

import appConfig from "@/app.config";
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
/**
 * Login component
 */
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    page: {
        title: "Login",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
    },
    data() {
        return {
            username: "",
            password: "",
            submitted: false,
            authError: null,
            tryingToLogIn: true,
            isAuthError: false,
        };
    },
    validations: {
        username: {
            required: helpers.withMessage("CWID is required", required),
        },
        password: {
            required: helpers.withMessage("Password is required", required),
        },
    },
    computed: {
        ...mapState("authfack", ["status"]),
        notification() {
            return this.$store ? this.$store.state.notification : null;
        },
    },
    methods: {
        ...authMethods,
        ...authFackMethods,
        ...notificationMethods,
        // Try to log the user in with the username
        // and password they provided.
        tryToLogIn() {
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            let self = this;
            this.submitted = true;
            // stop here if form is invalid
            axios
                .post(process.env.VUE_APP_BACKEND_URL_VERSION + "auth/login", {
                    username: this.username,
                    password: this.password,
                })
                .then((response) => {
                    if (response.data.data.access_token) {
                        var token = response.data.data.access_token;
                        localStorage.session = JSON.stringify(response.data.data.user);
                        localStorage.session_nama = response.data.data.user.name;
                        localStorage.session_nib = response.data.data.user.nib;
                        localStorage.access_token = token;
                        localStorage.kbli = null;
                        localStorage.reload_already = "no";
                        localStorage.dateLogin = Date.now();
                        localStorage.role_id = response.data.data.user.role_id;
                        let timerInterval;
                        Swal.fire({
                            icon: "success",
                            title: "Success",
                            text: "You will direct to dashboard page soon.",
                            timer: 2000,
                            timerProgressBar: true,
                            showCancelButton: false,
                            showConfirmButton: false,
                            // didOpen: () => {
                            //     timerInterval = setInterval(() => {
                            //         const content = Swal.getContent();
                            //         if (content) {
                            //             const b = content.querySelector("b");
                            //             if (b) {
                            //                 b.textContent = Swal.getTimerLeft();
                            //             }
                            //         }
                            //     }, 100);
                            // },
                            willClose: () => {
                                clearInterval(timerInterval);
                            },
                        }).then((result) => {
                            /* Read more about handling dismissals below */

                            // return false;
                            if (localStorage.session && localStorage.session_nama && localStorage.access_token) {
                                if (result.dismiss === Swal.DismissReason.timer) {
                                    localStorage.roles = response.data.data.user.role_slug;
                                    if (response.data.data.user.role_slug == "pemohon") {
                                        self.$router.push({ name: "pemohon-dashboard" });
                                    } else {
                                        self.$router.push({ name: "dashboard-admin" });
                                    }
                                }
                            }
                        });
                        // alert(this.access_token);
                        // setTimeout(this.redirectDashboard(), 1500);
                    }
                })
                .catch((error) => {
                    var message = error.response.data.data.error;
                    if (message == "Session has been used") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Your account is already login in another device, please logout first!",
                        });
                    } else if (message == "Account cant find") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Check again your CWID & Password!",
                        });
                    } else if (message == "Something went wrong") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Please try again later.",
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Sorry your account not found, please register.",
                        });
                    }
                });
        },
    },
    mounted() {},
};
</script>
<style scoped lang="scss">
body {
    background: url("../../../assets/images/bg_bayer_login1.jpg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
</style>
<template>
    <Layout>
        <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-5">
                <div class="card overflow-hidden">
                    <div class="bg-soft bg-primary">
                        <div class="row">
                            <div class="col-7">
                                <div class="text-primary p-4">
                                    <h5 class="text-primary">Welcome</h5>
                                    <p>Sign in with your CWID and password</p>
                                </div>
                            </div>
                            <div class="col-5 align-self-end">
                                <img src="@/assets/images/profile-img.png" alt class="img-fluid" />
                            </div>
                        </div>
                    </div>
                    <div class="card-body pt-0">
                        <div>
                            <router-link to="/">
                                <div class="avatar-md profile-user-wid mb-4">
                                    <span class="avatar-title rounded-circle bg-light">
                                        <img src="@/assets/images/logo_bayer.png" alt height="50" />
                                    </span>
                                </div>
                            </router-link>
                        </div>
                        <b-alert v-model="isAuthError" variant="danger" class="mt-3" dismissible>{{ authError }}</b-alert>
                        <div v-if="notification.message" :class="'alert ' + notification.type">
                            {{ notification.message }}
                        </div>

                        <b-form class="p-2" @submit.prevent="tryToLogIn">
                            <b-form-group class="mb-3" id="input-group-1" label="CWID" label-for="input-1">
                                <b-form-input id="input-1" v-model="username" type="text" placeholder="Enter CWID" :class="{ 'is-invalid': submitted && v$.username.$error }"></b-form-input>
                                <div v-for="(item, index) in v$.username.$errors" :key="index" class="invalid-feedback">
                                    <span v-if="item.$message">{{ item.$message }}</span>
                                </div>
                            </b-form-group>

                            <b-form-group class="mb-3" id="input-group-2" label="Password" label-for="input-2">
                                <b-form-input id="input-2" v-model="password" type="password" placeholder="Enter password" :class="{ 'is-invalid': submitted && v$.password.$error }"></b-form-input>
                                <div v-if="submitted && v$.password.$error" class="invalid-feedback">
                                    <span v-if="v$.password.required.$message">{{ v$.password.required.$message }}</span>
                                </div>
                            </b-form-group>
                            <!-- <b-form-checkbox class="form-check me-2 mt-0" id="customControlInline" name="checkbox-1" value="accepted" unchecked-value="not_accepted">Remember me </b-form-checkbox> -->
                            <div class="mt-3 d-grid">
                                <b-button type="submit" variant="primary" class="btn-block">Log In</b-button>
                            </div>

                            <div class="mt-4 text-center">
                                <!-- <router-link to="/forgot-password" class="text-muted"> <i class="mdi mdi-lock me-1"></i> Forgot your password? </router-link> -->
                            </div>
                        </b-form>
                    </div>
                    <!-- end card-body -->
                </div>
                <!-- end card -->

                <!-- end row -->
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </Layout>
</template>
